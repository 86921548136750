import { routes } from "@/routes";
import { getDisplayName, User } from "@/services/resources/user";
import { getCurrentLocale } from "@/utils/locale.utils";
import { notifications } from "@mantine/notifications";
import { UserId } from "@qrbite/shared/schema";
import { assert, assertDefined } from "@qrbite/shared/utils/general.utils";
import { PostgrestError } from "@supabase/supabase-js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import supabase from "../supabase";
import { updateAccount } from "../update-account";
import { handleUpsertUserData } from "./auth";

async function getAccount(): Promise<User | null> {
  const {
    data: { user },
    error: userError,
  } = await supabase.auth.getUser();

  if (userError) {
    throw userError;
  }

  if (user === null) return null;

  assert(user.email !== undefined, "Email is undefined");

  const { data, error } = await supabase
    .from("User")
    .select(
      `
      id,
      firstName,
      lastName,
      avatarUrl,
      locale
    `,
    )
    .eq("id", user.id)
    .single();

  let newUser;

  if (error) {
    // More than 1 or no items where returned when requesting a singular response.
    if (error.code === "PGRST116") {
      const { data: newUserRes, error: newUserError } =
        await handleUpsertUserData(user);

      if (newUserError) throw newUserError;

      newUser = newUserRes;
    } else {
      throw error;
    }
  } else {
    await updateLocaleCodeIfDifferent({
      userId: UserId.parse(user.id),
      savedLocale: data.locale,
    });
  }

  const userDetails = assertDefined(
    newUser ?? data,
    "User details are not defined",
  );

  return {
    id: UserId.parse(user.id),
    email: user.email,
    firstName: userDetails.firstName ?? undefined,
    lastName: userDetails.lastName ?? undefined,
    avatarUrl: userDetails.avatarUrl,
    displayName: getDisplayName({
      firstName: userDetails.firstName ?? undefined,
      lastName: userDetails.lastName ?? undefined,
      email: user.email,
    }),
  };
}

export const useGetAccount = () =>
  useQuery({
    queryKey: ["account"],
    queryFn: getAccount,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

export const useUpdateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<
    undefined,
    PostgrestError,
    { currentUser: User; newUser: User }
  >({
    mutationFn: async ({ currentUser, newUser }) => {
      await updateAccount(currentUser, newUser, console.log);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["account"] });
      notifications.show({
        title: "Account updated",
        message: "Your account has been successfully updated",
      });
    },
    onError: (error) => {
      notifications.show({ message: error.message, color: "red" });
    },
  });
};

export const useUpdateEmail = () => {
  const queryClient = useQueryClient();

  return useMutation<undefined, PostgrestError, { email: string }>({
    mutationFn: async ({ email }) => {
      const { error } = await supabase.auth.updateUser(
        { email },
        {
          emailRedirectTo: `${window.location.origin}${routes.account.settings}`,
        },
      );

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["account"] });
      notifications.show({
        title: "Verification emails sent",
        message:
          "A verification email has been sent to both your old and new email addresses",
      });
    },
    onError: (error) => {
      notifications.show({ message: error.message, color: "red" });
    },
  });
};

export const useUpdatePassword = () => {
  return useMutation<undefined, PostgrestError, { password: string }>({
    mutationFn: async ({ password }) => {
      const { error } = await supabase.auth.updateUser({ password });

      if (error) throw error;
    },
    onSuccess: () => {
      notifications.show({
        title: "Password updated",
        message: "Your password has been successfully updated",
      });
    },
    onError: (error) => {
      notifications.show({ message: error.message, color: "red" });
    },
  });
};

async function updateLocaleCodeIfDifferent(params: {
  userId: UserId;
  savedLocale: string | null;
}) {
  const currentLocale = getCurrentLocale();

  if (params.savedLocale === currentLocale) return;

  await supabase
    .from("User")
    .update({ locale: currentLocale })
    .eq("id", params.userId);

  console.debug("Updated locale in database", {
    userId: params.userId,
    from: params.savedLocale,
    to: currentLocale,
  });
}
